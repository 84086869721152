@font-face {
  font-family: Heebo-Regular;
  src: url('./assets/fonts/Heebo-Regular.ttf');
}

@font-face {
  font-family: Heebo-Bold;
  src: url('./assets/fonts/Heebo-Bold.ttf');
}

html>* {
  font-family: Heebo-Regular;
}

body {
  margin: 0;
}


/* ant design mobile css changes  */
.adm-list-item-content-extra * {
  width: 25px;
  height: 25px;
}

body .adm-input-element {
  text-align: right !important;
}

.adm-search-bar .adm-search-bar-input-box {
  flex-direction: row-reverse;
}

.adm-selector .adm-space.adm-space * {
  margin: 0 !important;
}

.adm-selector .adm-space.adm-space .adm-space-item>* {
  width: 120px;
}

.adm-selector .adm-space.adm-space {
  gap: 0 8px !important;
}

.choose-restaurant-list{
  /* --border-bottom:0px solid white!important; */
  --border-top:0px solid white!important;
}

.textarea-parameters *{
  border: none;
}

.textarea-parameters textarea{
  padding: 0px 11px;
  
}

.textarea-parameters textarea::placeholder{
 color: #bfbfbfb5;
  font-weight: 300;
}

.textarea-parameters *:focus-visible{
  border: none!important;
  outline: none!important;
}